import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="Welcome-text">
          hey there! thanks for checking out the site.
          unfortunately it's still under construction,
          but feel free to check out some of LA's
          best through any of the curated links below:
        </p>
        <a
          className="App-link"
          href="https://a2b2.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          A2B2
        </a>
        <a
          className="App-link"
          href="https://www.instagram.com/rhondaintl/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          A Club Called Rhonda
        </a>
        <a
          className="App-link"
          href="https://baveldtla.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bavel
        </a>
        <a
          className="App-link"
          href="https://bestiala.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bestia
        </a>
        <a
          className="App-link"
          href="https://boysmells.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Boy Smells
        </a>
        <a
          className="App-link"
          href="https://wearebraindead.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Brain Dead / Studios Fairfax
        </a>
        <a
          className="App-link"
          href="https://cantersdeli.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Canter's Fairfax
        </a>
        <a
          className="App-link"
          href="https://chapteronerecords.co/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chapter One Records
        </a>
        <a
          className="App-link"
          href="https://dayglow.coffee"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dayglow
        </a>
        <a
          className="App-link"
          href="https://daughtersdeli.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Daughter's Deli
        </a>
        <a
          className="App-link"
          href="https://destroyer.la"
          target="_blank"
          rel="noopener noreferrer"
        >
          Destroyer
        </a>
        <a
          className="App-link"
          href="https://dublab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dublab
        </a>
        <a
          className="App-link"
          href="https://www.instagram.com/theduskcamp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Dusk Camp
        </a>
        <a
          className="App-link"
          href="https://faworldentertainment.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fucking Awesome
        </a>
        <a
          className="App-link"
          href="https://insheepsclothinghifi.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          In Sheep's Clothing
        </a>
        <a
          className="App-link"
          href="https://catch.one/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Jewel's Catch One
        </a>
        <a
          className="App-link"
          href="https://johnelliott.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          John Elliott
        </a>
        <a
          className="App-link"
          href="http://lightsdownlow.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lights Down Low
        </a>
        <a
          className="App-link"
          href="https://missgrass.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Miss Grass
        </a>
        <a
          className="App-link"
          href="https://www.instagram.com/mountanalog/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mount Analog
        </a>
        <a
          className="App-link"
          href="https://thenewbev.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          The New Beverly
        </a>
        <a
          className="App-link"
          href="https://nightmarketla.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          NIGHT + MARKET
        </a>
        <a
          className="App-link"
          href="https://sqirlla.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sqirl
        </a>
        <a
          className="App-link"
          href="https://tsujita-annex.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tsujita Annex
        </a>
        <a
          className="App-link"
          href="https://store.unionlosangeles.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Union
        </a>
        <a
          className="App-link"
          href="https://virgilnormal.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Virgil Normal
        </a>
        <a
          className="App-link"
          href="https://zebulon.la/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zebulon
        </a>
        <div className="footer">
          <p>
            get in touch:
            <br></br> 
            hello@arianowji.com
            <br></br>
            <a href="https://www.instagram.com/arianowji">
              instagram
            </a>
            <br></br>
            <a href="https://drive.google.com/file/d/1dojjo0VS8YSrIdUrb8FRlB7j9zcYq7-y/view?usp=sharing">
            resume
          </a>
          </p>
      </div>
      </header>
    </div>
  );
}

export default App;
